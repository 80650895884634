.logotype {
  display: grid;
  grid-template: auto auto / 1fr;
  justify-items: center;
  gap: 1vmin 0;
  margin: auto;
  filter: drop-shadow(0 0.3vmin 0.4vmin rgb(0, 0, 0));

  &__star {
    &-svg {
      width: max(5vmin, 32px);
      height: max(5vmin, 32px);
    }
  }

  &__champion {
    &-svg {
      width: max(11vmin, 64px);
      aspect-ratio: 20 / 6;
      fill: var(--c-white);
    }
  }
}
