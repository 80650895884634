.gain-message {
  position: relative;
  width: min(90vw, 600px);
  padding: 2vmin 0;
  color: var(--c-white);
  line-height: 1;
  font-size: clamp(28px, 4.8vw, 48px);
  font-weight: 900;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSIgZmlsbD0ibm9uZSI+PGNpcmNsZSBjeD0iMTIiIGN5PSIxMiIgcj0iMTEiIGZpbGw9InVybCgjYSkiLz48ZGVmcz48cmFkaWFsR3JhZGllbnQgaWQ9ImEiIGN4PSIwIiBjeT0iMCIgcj0iMSIgZ3JhZGllbnRUcmFuc2Zvcm09Im1hdHJpeCgxMSAwIDAgMTEgMTIgMTIpIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSI+PHN0b3Agb2Zmc2V0PSIuMTk5IiBzdG9wLW9wYWNpdHk9Ii43NSIvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1vcGFjaXR5PSIwIi8+PC9yYWRpYWxHcmFkaWVudD48L2RlZnM+PC9zdmc+);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 130% 100%;

  &__item {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;

    &:not(:first-of-type) {
      margin-top: -1em;
    }

    .char {
      will-change: transform;
      backface-visibility: hidden;
    }

    &.slide-null .char {
      transform: translateY(-0.5em) rotateX(-90deg);
    }

    &.slide-in .char {
      transform: translateY(0) rotateX(0deg);
      animation: slide-in 425ms cubic-bezier(.3, 0, .7, 1) both;
      animation-delay: calc(50ms * var(--char-index));

      @keyframes slide-in {
        to {
          transform: translateY(-0.5em) rotateX(-90deg);
        }
      }
    }

    &.slide-out .char {
      transform: translateY(0.5em) rotateX(90deg);
      animation: slide-out 425ms cubic-bezier(.3, 0, .7, 1) both;
      animation-delay: calc(50ms * var(--char-index));

      @keyframes slide-out {
        to {
          transform: translateY(0) rotateX(0deg);
        }
      }
    }
  }
}

.gain-message-one {

}

.gain-message-two {
  color: Gold;

  transform: rotate(-2.5deg);
  animation: libration 1250ms infinite alternate ease-in-out;

  @-webkit-keyframes libration {
    to {
      transform: rotate(2.5deg);
    }
  }
}

.gain-message-three {
  color: Gold;

  transform: rotate(-1.5deg);
  animation: libration 1250ms infinite alternate ease-in-out;

  @-webkit-keyframes libration {
    to {
      transform: rotate(1.5deg);
    }
  }
}
