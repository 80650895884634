.form {
  position: relative;
  z-index: var(--z-index-registration-form);
  display: grid;
  gap: 20px 0;
  padding: 21px;
  width: min(calc(100vw - 40px), 370px);
  min-height: 100px;
  background-color: var(--c-white);
  border-radius: 21.5px;
  filter: drop-shadow(0 1vmin 2vmin rgb(0, 0, 0, 0.7));

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-background-clip: text;
    -webkit-text-fill-color: #000000;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #00000000;
  }
}

.select-option {
  font-size: 16px;
}

.text-field-container {
  position: relative;
}

.select-option {
  &--country {
    .select-option-trigger,
    .select-option-list-item {
      padding-left: 54px;

      &--rub {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djZIMFYwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggc3Ryb2tlPSIjRDBEMEQwIiBkPSJNLjUuNWgyM3YxN0guNVYuNVoiLz48cGF0aCBmaWxsPSIjMDAzOUE2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDZoMjR2NkgwVjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjRDUyQjFFIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyaDI0djZIMHYtNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==);
      }

      &--kzt {
        background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAxQTVDOCIgZD0iTTAgMGgyNHYxOEgweiIvPjxwYXRoIHN0cm9rZT0iI0ZGRTcwMCIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBkPSJtOCAxMSA0LjUgM00xMS41IDE0bDQuNS0zIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIzIiBmaWxsPSIjRkZFNzAwIi8+PC9zdmc+);
      }

      &--rub,
      &--kzt {
        background-position: center left 18px;
        background-size: 24px 18px;
        background-repeat: no-repeat;
      }
    }
  }
}

.btn-auth {
  display: grid;
  place-content: center;
  width: 100%;
  height: 42px;
  margin-top: 16px;
  padding: 0 20px;
  color: White;
  font-weight: 700;
  font-size: 15px;
  border-radius: 42px;
  border: none;
  background: linear-gradient(0.25turn, #EB0291, #AC09E7);
  cursor: pointer;
}

.promo-code {
  position: absolute;
  inset: 0;
  z-index: 1;
  background-color: var(--c-white);
  color: #3B7CED;
  align-items: center;
  font-size: 13px;
  font-weight: 700;

  span {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    padding: 0 0 0 2px;
    height: 100%;
  }
}

.auth-rules {
  --tickbox-checkbox-radius: 5px;

  margin: -10px 0;
  padding: 0;
  display: flex;

  label {
    margin-left: -10px;
    font-size: 13px;

    span:first-child {
      white-space: unset;
      text-overflow: unset;
      overflow: auto;
    }
  }

  a {
    color: #3B7CED;

    &:hover {
      text-decoration: none;
    }
  }
}
