.payment-brands {
  display: grid;
  grid-template: auto / repeat(5, auto);
  gap: 0 4em;
  margin: auto;
  width: min(90%, 400px);
  filter: drop-shadow(0 0.3vmin 0.4vmin rgb(0, 0, 0));

  @include min(tablet-portrait) {
    width: min(90%, 500px);
  }

  .svg-icon--payment-brands {
    width: 100%;
    max-width: none;
    aspect-ratio: 100 / 54;
    fill: var(--c-white);
  }
}
