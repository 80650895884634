.your-bonus {
  width: fit-content;
  margin: auto;
  color: var(--c-white);
  line-height: 1;
  font-size: clamp(18px, 2.6vw, 26px);
  font-weight: 900;
  text-shadow: #000 1px 0 10px;
  opacity: 0;
  transition: transform 725ms 1275ms ease-out, opacity 725ms 1275ms ease-out;

  body.first-spin-complete & {
    opacity: 1;
  }
}
