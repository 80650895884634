canvas#noisy-ring {
  background: transparent;
  pointer-events: none;
  display: block;
  position: absolute;
  inset: 0 0 0 -38%;
  z-index: 3;
  margin: auto;
  width: 173%;
  height: 173%;
}
