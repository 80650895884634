html,
body {
  position: relative;
}

body {
  overflow: hidden auto;
  width: 100vw;
  background-color: var(--c-black);

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }
}

.page {
  overflow: hidden;
  position: relative;
  z-index: 2;
  display: grid;
  gap: var(--v-offset-sm) 0;

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }

  &__logotype {
    position: relative;
    z-index: 10;
    place-self: center;
    padding-top: calc(var(--v-offset-sm) / 2);
  }

  &__gain {
    position: relative;
    z-index: 10;
    place-self: center;
  }

  &__wheel {
    position: relative;
    width: fit-content;
    aspect-ratio: 1;
    margin: auto;
  }

  &__payment {
    position: relative;
    z-index: 10;
    place-self: center;
    padding-bottom: calc(var(--v-offset-sm) / 2);
  }
}
